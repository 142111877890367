import {
    Checkbox,
    createMuiTheme,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    ThemeProvider,
} from "@material-ui/core";
import { SelectChangeEvent } from "@mui/material/Select";
import React, { useState, useEffect } from "react";
import { IFilters,dataEnglishLanguageLevel,dataGender,dataIndustries,dataPolishLanguageLevel,dataUniversities,dataYearOfStudy,dataYearsOfBirth } from "../constants";
import { connect } from "react-redux";
import { updateFilters } from "../actions";
import { Padding } from "@mui/icons-material";

const MenuProps = {
    PaperProps: {
        style: {
            width: 250,
        },
    },
};

const theme = createMuiTheme({
    overrides: {
        MuiInputLabel: {
            root: {
                color: "lightgray",
                "&$focused": {
                    color: "#F78F27",
                },
            },
        },
        MuiInput: {
            underline: {
                "&:after": {
                    borderBottom: "2px solid #F78F27",
                },
            },
        },
    },
});

interface IProps {
    updateFilters: (filters: IFilters) => void;
}

const Filters: React.FC<IProps> = ({ updateFilters }) => {
    const [filters, setFilters] = useState<IFilters>({
        university: [],
        yearOfBirth: [],
        yearOfStudy: [],
        gender: [],
        polishLanguageLevel: [],
        englishLanguageLevel: [],
        industries: []
    });

    useEffect(() => {
        //console.log("state changed 🤠");
        //console.log(filters);
        updateFilters(filters);
    }, [filters]);

    const handleChangeUniversity = (e: SelectChangeEvent<typeof filters.university>) => {
        const {
            target: { value },
        } = e;

        setFilters({
            ...filters,
            university: typeof value === "string" ? value.split(",") : value,
        });
    };
    const handleChangeStudy = (e: SelectChangeEvent<typeof filters.yearOfStudy>) => {
        const {
            target: { value },
        } = e;

        setFilters({
            ...filters,
            yearOfStudy: typeof value === "string" ? value.split(",") : value,
        });
    };

    const handleChangeBirth = (e: SelectChangeEvent<typeof filters.yearOfBirth>) => {
        const {
            target: { value },
        } = e;

        setFilters({
            ...filters,
            yearOfBirth: typeof value === "string" ? value.split(",") : value,
        });
    };

    const handleChangeGender = (e: SelectChangeEvent<typeof filters.gender>) => {
        const {
            target: { value },
        } = e;

        setFilters({
            ...filters,
            gender: typeof value === "string" ? value.split(",") : value,
        });
    };

    const handleChangeEnglish = (e: SelectChangeEvent<typeof filters.englishLanguageLevel>) => {
        const {
            target: { value },
        } = e;

        setFilters({
            ...filters,
            englishLanguageLevel: typeof value === "string" ? value.split(",") : value,
        });
    };

    const handleChangePolish = (e: SelectChangeEvent<typeof filters.polishLanguageLevel>) => {
        const {
            target: { value },
        } = e;

        setFilters({
            ...filters,
            polishLanguageLevel: typeof value === "string" ? value.split(",") : value,
        });
    };

    const handleChangeIndustries = (e: SelectChangeEvent<typeof filters.industries>) => {
        const {
            target: { value },
        } = e;

        setFilters({
            ...filters,
            industries: typeof value === "string" ? value.split(",") : value,
        });
    };

    return (
        <div
            className="filters"
            style={{
                padding: 25,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }}
        >
            <ThemeProvider theme={theme}>
                <FormControl style={{ width: 300, marginBottom: 30} }>
                    <InputLabel id="university-checkbox" style={{
                                            
                                        }}>
                        Uczelnia
                    </InputLabel >
                    <Select
                        labelId="university-checkbox-label"
                        id="university-checkbox"
                        multiple
                        value={filters.university}
                        onChange={(e) => handleChangeUniversity(e as any)}
                        renderValue={(selected: any) => selected.join(", ")}
                        MenuProps={MenuProps}
                        
                    >
                        {dataUniversities.map((university, i) => {
                            return (
                                <MenuItem key={i} value={university}>
                                    <Checkbox
                                        checked={
                                            filters.university.indexOf(university) > -1
                                        }
                                        style={{
                                            color: "#F78F27",

                                        }}
                                    />
                                    <ListItemText primary={university} />
                                </MenuItem>
                            );
                        })}
                    </Select>
                    </FormControl>

                  
                <FormControl style={{ width: 300, marginBottom: 30 }}>
                    <InputLabel id="yearOfStudy-checkbox" style={{
                                        }}>
                        Rok studiów
                    </InputLabel>
                    <Select
                        labelId="yearOfStudy-checkbox-label"
                        id="yearOfStudy-checkbox"
                        multiple
                        value={filters.yearOfStudy}
                        onChange={(e) => handleChangeStudy(e as any)}
                        renderValue={(selected: any) => selected.join(", ")}
                        MenuProps={MenuProps}
                    >
                        {dataYearOfStudy.map((yearOfStudy, i) => {
                            return (
                                <MenuItem key={i} value={yearOfStudy}>
                                    <Checkbox
                                        checked={
                                            filters.yearOfStudy.indexOf(yearOfStudy) > -1
                                        }
                                        style={{
                                            color: "#F78F27",

                                        }}
                                    />
                                    <ListItemText primary={yearOfStudy} />
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>

                <FormControl style={{ width: 300, marginBottom: 30 }}>
                    <InputLabel id="industries-checkbox" style={{
                                        }}>
                        Branże
                    </InputLabel>
                    <Select
                        labelId="industries-checkbox-label"
                        id="industries-checkbox"
                        multiple
                        value={filters.industries}
                        onChange={(e) => handleChangeIndustries(e as any)}
                        renderValue={(selected: any) => selected.join(", ")}
                        MenuProps={MenuProps}
                    >
                        {dataIndustries.map((industries, i) => {
                            return (
                                <MenuItem key={i} value={industries}>
                                    <Checkbox
                                        checked={
                                            filters.industries.indexOf(industries) > -1
                                        }
                                        style={{
                                            color: "#F78F27",

                                        }}
                                    />
                                    <ListItemText primary={industries} />
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <FormControl style={{ width: 300 , marginBottom: 30}}>
                    <InputLabel id="englishLanguageLevel-checkbox" style={{
                                        }}>
                        Znajomość języka angielskiego
                    </InputLabel>
                    <Select
                        labelId="englishLanguageLevel-checkbox-label"
                        id="englishLanguageLevel-checkbox"
                        multiple
                        value={filters.englishLanguageLevel}
                        onChange={(e) => handleChangeEnglish(e as any)}
                        renderValue={(selected: any) => selected.join(", ")}
                        MenuProps={MenuProps}
                    >
                        {dataEnglishLanguageLevel.map((englishLanguageLevel, i) => {
                            return (
                                <MenuItem key={i} value={englishLanguageLevel}>
                                    <Checkbox
                                        checked={
                                            filters.englishLanguageLevel.indexOf(englishLanguageLevel) > -1
                                        }
                                        style={{
                                            color: "#F78F27",

                                        }}
                                    />
                                    <ListItemText primary={englishLanguageLevel} />
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <FormControl style={{ width: 300 , marginBottom: 30}}>
                    <InputLabel id="polishLanguageLevel-checkbox" style={{
                                        }}>
                        Znajomość języka polskiego
                    </InputLabel>
                    <Select
                        labelId="polishLanguageLevel-checkbox-label"
                        id="polishLanguageLevel-checkbox"
                        multiple
                        value={filters.polishLanguageLevel}
                        onChange={(e) => handleChangePolish(e as any)}
                        renderValue={(selected: any) => selected.join(", ")}
                        MenuProps={MenuProps}
                    >
                        {dataPolishLanguageLevel.map((polishLanguageLevel, i) => {
                            return (
                                <MenuItem key={i} value={polishLanguageLevel}>
                                    <Checkbox
                                        checked={
                                            filters.polishLanguageLevel.indexOf(polishLanguageLevel) > -1
                                        }
                                        style={{
                                            color: "#F78F27",

                                        }}
                                    />
                                    <ListItemText primary={polishLanguageLevel} />
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <FormControl style={{ width: 300 , marginBottom: 30}}>
                    <InputLabel id="gender-checkbox" style={{
                                        }}>
                        Płeć
                    </InputLabel>
                    <Select
                        labelId="gender-checkbox-label"
                        id="gender-checkbox"
                        multiple
                        value={filters.gender}
                        onChange={(e) => handleChangeGender(e as any)}
                        renderValue={(selected: any) => selected.join(", ")}
                        MenuProps={MenuProps}
                    >
                        {dataGender.map((gender, i) => {
                            return (
                                <MenuItem key={i} value={gender}>
                                    <Checkbox
                                        checked={
                                            filters.gender.indexOf(gender) > -1
                                        }
                                        style={{
                                            color: "#F78F27",

                                        }}
                                    />
                                    <ListItemText primary={gender} />
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <FormControl style={{ width: 300, marginBottom: 30 }}>
                    <InputLabel id="yearOfBirth-checkbox" style={{
                                        }}>
                        Rok urodzenia 
                    </InputLabel>
                    <Select
                        labelId="yearOfBirth-checkbox-label"
                        id="yearOfBirth-checkbox"
                        multiple
                        value={filters.yearOfBirth}
                        onChange={(e) => handleChangeBirth(e as any)}
                        renderValue={(selected: any) => selected.join(", ")}
                        MenuProps={MenuProps}
                    >
                        {dataYearsOfBirth.map((yearOfBirth, i) => {
                            return (
                                <MenuItem key={i} value={yearOfBirth}>
                                    <Checkbox
                                        checked={
                                            filters.yearOfBirth.indexOf(yearOfBirth) > -1
                                        }
                                        style={{
                                            color: "#F78F27",
                                        }}
                                    />
                                    <ListItemText primary={yearOfBirth} />
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                {/*<FormControl>
                    <InputLabel id="year-label">Rok studiów</InputLabel>
                    <Select
                        value={filters.minYear}
                        label="year"
                        onChange={(e) =>
                            setFilters({
                                ...filters,
                                minYear: e.target.value as number,
                            })
                        }
                    >
                        <MenuItem value={0}>
                            <em>Brak wyboru</em>
                        </MenuItem>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                    </Select>
                    </FormControl>*/}
            </ThemeProvider>
        </div>
    );
};

const mapDispatchAsProps = (dispatch: any) => ({
    updateFilters: (filters: IFilters) => dispatch(updateFilters(filters)),
});

export default connect(null, mapDispatchAsProps)(Filters);
