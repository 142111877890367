import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { IUser, serverUrl } from "../constants";
import { logInUser } from "../actions";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

const theme = createTheme({
    palette: {
        background: {
            default: "#e3e3e3",
        },
    },
});

interface IProps {
    logIn: (user: IUser) => void;
    user: IUser;
}

const SignIn: React.FC<IProps> = ({ logIn, user }) => {
    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const res = await fetch(`${serverUrl}/login`, {
            method: "POST",
            body: JSON.stringify({
                username: data.get("email"),
                password: data.get("password"),
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (res.status == 200) {
            const token = (await res.json()).token;
            const user: IUser = {
                userName: data.get("email") as string,
                JWT: token,
            };
            logIn(user);
        } else {
            window.alert("Niepoprawne dane logowania");
        }
    };

    if (user.JWT != "") {
        return <Navigate to="/" />;
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 30,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "#FDF1E5",
                        borderRadius: 3,
                        boxShadow: 6,
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                    }}
                >
                    <Typography
                        component="h1"
                        variant="h5"
                        color="#7A7A7A"
                        fontSize="normal"
                        fontWeight="bold"
                        fontFamily="Rockwell"
                        padding="15px"
                    >
                        Logowanie
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{
                            mt: 0,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <TextField
                            margin="normal"
                            required
                            id="email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            size="small"
                            style={{ width: "100%" }}
                            placeholder="Login"
                            sx={{
                                input: {
                                    backgroundColor: "#E5DCD4",
                                    fontFamily: "Rockwell",
                                    color: "black",
                                    boxShadow: "10",
                                },
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            name="password"
                            type="password"
                            id="password"
                            size="small"
                            autoComplete="current-password"
                            style={{ width: "100%" }}
                            placeholder="Hasło"
                            sx={{
                                input: {
                                    backgroundColor: "#E5DCD4",
                                    fontFamily: "Rockwell",
                                    color: "black",
                                    boxShadow: "10",
                                },
                            }}
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 2,
                                mb: 2,
                                backgroundColor: "#FD590E",
                                width: "70%",
                                fontWeight: "bold",
                                fontFamily: "Rockwell",
                                textTransform: "none",
                            }}
                        >
                            Zaloguj się
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

const mapDispatchAsProps = (dispatch: any) => ({
    logIn: (user: IUser) => dispatch(logInUser(user)),
});

const mapStateAsProps = (state: any) => ({
    user: state.user,
});

export default connect(mapStateAsProps, mapDispatchAsProps)(SignIn);
