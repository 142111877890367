import { createStore, combineReducers } from "redux";
import userReducer from "../reducers/userReducer";
import filtersReducer from "../reducers/filtersReducer";

const rootReducer = combineReducers({
    user: userReducer,
    filters: filtersReducer,
});

const configureStore = () => {
    return createStore(rootReducer);
};

export default configureStore;
