
import { ICV, IFilters, IUser } from "../constants";

export const logInUser = (user: IUser) => ({
    type: "LOGIN",
    payload: user,
});

export const logOutUser = () => ({
    type: "LOGOUT",
    payload: null,
});

export const loadCVs = (CVs: Array<ICV>) => ({
    type: "LOAD_CVS",
    payload: CVs,
});

export const updateFilters = (filter: IFilters) => ({
    type: "UPDATE_FILTER",
    payload: filter,
});