import {
    Button,
    CssBaseline,
    Drawer,
    IconButton,
    makeStyles,
    useMediaQuery,
} from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { ICV, IFilters, IUser, serverUrl } from "../constants";
import MenuIcon from "@material-ui/icons/Menu";
import Filters from "./Filters";
//import { data } from "../constants/mockData";
import SettingsIcon from "@material-ui/icons/Settings";
import { DataGrid, GridToolbar, plPL } from "@mui/x-data-grid";
import { useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { RenderCellExpand } from "./renderCellExpand";
import { logOutUser } from "../actions";
const drawerWidth = 400;

const loadCVs = async (user: IUser) => {
    //console.log(`Bearer ${user.JWT}`);
    const res = await fetch(`${serverUrl}/users`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${user.JWT}`,
            // Authorization: `Bearer 10xd`,
        },
        // mode: "no-cors",
    });
    //console.log(await res.json());
    const data = await res.json();
    return data;
};

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        height: "85vh",
    },
    drawer: {
        flexShrink: 0,
        width: drawerWidth,
        maxWidth: "80vw",
        padding: "0 auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    drawerPaper: {
        width: drawerWidth,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
        backgroundColor: "#FB8D3C",
        width: "3rem",
        height: "3rem",
        color: "white",
        margin: "2rem",
    },
    mainHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    logOutButton: {
        backgroundColor: "#FB8D3C",
        height: "3rem",
        color: "white",
    },
}));

const theme = createTheme({
    typography: {
        fontFamily: "Arial",
    },
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: "#F78F27",
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: "#F78F27",
            // dark: will be calculated from palette.secondary.main,
            //contrastText: '#ffcc00',
        },
    },
});

interface IProps {
    user: IUser;
    filters: IFilters;
    logOut: () => void;
}

const App: React.FC<IProps> = ({ user, filters, logOut }) => {
    const classes = useStyles();

    const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState<Array<ICV>>([]);

    useEffect(() => {
        const dupa = async () => {
            const array = loadCVs(user);
            setData(await array);
        };
        dupa();
    }, []);

    const toggleDrawer = (event: any) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setOpen(!open);
    };
    const replaceValue = (value: Boolean) => {
        if (value) {
            return "Tak";
        } else {
            return "Nie";
        }
    };

    const industriesEvolve = (industries: any) => {
        const array = Array.from(
            new Set(
                industries.flatMap((x: { value: string }) =>
                    x.value.split(", ")
                )
            )
        );
        return array;
    };
    const columns = [
        {
            field: "cvUrl",
            headerName: "CV",
            renderCell: (params: any) => (
                <a
                    href={params.value}
                    style={{ color: "#F78F27", fontWeight: "bold" }}
                >
                    CV
                </a>
            ),
        },
        { field: "firstName", headerName: "Imię", width: 200 },
        { field: "lastName", headerName: "Nazwisko", width: 200 },
        { field: "degree", headerName: "Kierunek studiów", width: 300 },
        { field: "yearOfStudy", headerName: "Rok studiów", width: 200 },
        { field: "yearOfBirth", headerName: "Rok urodzenia", width: 200 },
        { field: "phone", headerName: "Numer telefonu", width: 200 },
        { field: "email", headerName: "Adres e-mail", width: 200 },
        { field: "university", headerName: "Uczelnia", width: 300 },
        { field: "sex", headerName: "Płeć", width: 200 },
        { field: "isInCracow", headerName: "Mieszka w Krakowie", width: 200 },
        {
            field: "polishLanguageLevel",
            headerName: "Znajomość j. polskiego",
            width: 200,
        },
        {
            field: "englishLanguageLevel",
            headerName: "Znajomość j. angielskiego",
            width: 200,
        },
        { field: "otherLanguage", headerName: "Inny jezyk obcy", width: 200 },
        {
            field: "otherLanguageLevel",
            headerName: "Znajomość tego j. obcego",
            width: 200,
        },
        {
            field: "githubLink",
            headerName: "Github",
            width: 200,
            renderCell: (params: any) => {
                if (params.value)
                    return (
                        <a
                            href={params.value}
                            style={{ color: "#F78F27", fontWeight: "bold" }}
                        >
                            Github
                        </a>
                    );
                return "-";
            },
        },
        {
            field: "linkedinLink",
            headerName: "Linkedin",
            width: 200,
            renderCell: (params: any) => {
                if (params.value)
                    return (
                        <a
                            href={params.value}
                            style={{ color: "#F78F27", fontWeight: "bold" }}
                        >
                            Linkedin
                        </a>
                    );
                return "-";
            },
        },
        {
            field: "industries",
            headerName: "Branże",
            width: 200,
            renderCell: RenderCellExpand,
        },
    ];

    const rows = data.map((cv, i) => {
        return {
            id: i,
            firstName: cv.firstName,
            lastName: cv.lastName,
            degree: cv.degree,
            yearOfStudy: cv.yearOfStudy,
            yearOfBirth: cv.yearOfBirth,
            phone: cv.phone,
            email: cv.email,
            university: cv.university,
            sex: cv.sex,
            isInCracow: replaceValue(Boolean(cv.isInCracow)),
            polishLanguageLevel: cv.polishLanguageLevel,
            englishLanguageLevel: cv.englishLanguageLevel,
            otherLanguage: cv.otherLanguage,
            otherLanguageLevel: cv.otherLanguageLevel,
            githubLink: cv.githubLink,
            linkedinLink: cv.linkedinLink,
            industries: industriesEvolve(cv.industries),
            cvUrl: cv.cvUrl,
        };
    });

    if (user.JWT == "") {
        return <Navigate to="/login" />;
    }
    const getArraysIntersection = (a1: any, a2: any) => {
        for (let i = 0; i < a2.length; i++) {
            if (a1.has(a2[i])) return true;
        }
        return false;
    };
    const filtrRows: any = (rows: any) => {
        const setUniversity = new Set(filters.university);
        const setEnglish = new Set(filters.englishLanguageLevel);
        const setPolish = new Set(filters.polishLanguageLevel);
        const setGender = new Set(filters.gender);
        const setIndustries = new Set(filters.industries);
        const setYearOfBirth = new Set(filters.yearOfBirth);
        const setYearOfStudy = new Set(filters.yearOfStudy);
        var newRows = rows;
        if (filters.university.length != 0) {
            newRows = newRows.filter((r: any) =>
                setUniversity.has(r.university)
            );
        }
        if (filters.englishLanguageLevel.length != 0) {
            newRows = newRows.filter((r: any) =>
                setEnglish.has(r.englishLanguageLevel)
            );
        }
        if (filters.polishLanguageLevel.length != 0) {
            newRows = newRows.filter((r: any) =>
                setPolish.has(r.polishLanguageLevel)
            );
        }
        if (filters.gender.length != 0) {
            newRows = newRows.filter((r: any) => setGender.has(r.sex));
        }
        if (filters.industries.length != 0) {
            //console.log(getArraysIntersection(["a","ss","sss"],["ss","a"]))
            newRows = newRows.filter((r: any) =>
                getArraysIntersection(setIndustries, r.industries)
            );
        }
        if (filters.yearOfBirth.length != 0) {
            newRows = newRows.filter((r: any) =>
                setYearOfBirth.has(r.yearOfBirth)
            );
        }
        if (filters.yearOfStudy.length != 0) {
            newRows = newRows.filter((r: any) =>
                setYearOfStudy.has(r.yearOfStudy)
            );
        }
        //console.log(filters)
        //console.log(newRows)
        return newRows;
    };
    return (
        <ThemeProvider theme={theme}>
            <div style={{ display: "flex" }}>
                <CssBaseline />

                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={toggleDrawer}
                    className={classes.menuButton}
                >
                    <MenuIcon />
                </IconButton>
                <Drawer
                    className={classes.drawer}
                    variant={isMdUp ? "permanent" : "temporary"}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    anchor="left"
                    open={open}
                    onClose={toggleDrawer}
                >
                    <h1 style={{ fontSize: 20, color: "#F78F27", padding: 20 }}>
                        Szczegółowe filtry
                    </h1>
                    <Filters />
                </Drawer>

                <main className={classes.content}>
                    <header className={classes.mainHeader}>
                        <div className="welcomeText">
                            <h1 style={{ fontSize: 20, color: "#F78F27" }}>
                                Baza danych CV
                            </h1>
                            <p>
                                Witamy, poniżej przedstawiamy dostępne dane
                                uczestników Inżynierskich Targów Pracy, którzy
                                postanowili Państwu udostępnić swoje CV.{" "}
                            </p>
                        </div>
                        <Button
                            className={classes.logOutButton}
                            onClick={async () => {
                                /*const selectedRows = rows.filter((r) =>
                                    set1.has(r.degree)
                                );
                                setSelectedRows(selectedRows);*/
                                // const array = loadCVs(user);
                                // setData(await array);
                                logOut();
                            }}
                        >
                            Wyloguj się
                        </Button>
                    </header>

                    <DataGrid
                        rows={filtrRows(rows)}
                        columns={columns}
                        initialState={{
                            pagination: {
                                pageSize: 50,
                            },
                        }}
                        components={{
                            ColumnMenuIcon: SettingsIcon,
                            Toolbar: GridToolbar,
                        }}
                        localeText={
                            plPL.components.MuiDataGrid.defaultProps.localeText
                        }
                    />

                    {/* <div className="content">
                    <h2>Złożone CV:</h2>
                    <div className="CVs">
                        <List>
                            {data.map((cv, i) => {
                                return (
                                    <CVEntry
                                        cv={cv}
                                        key={i}
                                        isGray={i % 2 == 0}
                                    />
                                );
                            })}
                        </List>
                    </div>
                </div> */}
                </main>
            </div>
        </ThemeProvider>
    );
};

const mapStateAsProps = (state: any) => ({
    user: state.user,
    filters: state.filters,
});

const mapDispatchAsProps = (dispatch: any) => ({
    logOut: () => dispatch(logOutUser()),
});

export default connect(mapStateAsProps, mapDispatchAsProps)(App);
