export interface IUser {
    userName: string;
    JWT: string;
}


export type ActionType = "LOGIN" | "LOGOUT" | "LOAD_CVS";

export interface IAction {
    type: ActionType;
    payload: any;
}


    
export interface ICV {
    firstName: string;
    lastName: string;
    degree: string;
    yearOfStudy: number;
    yearOfBirth: number,
    phone: number,
    email: string,
    university: string, 
    sex: string,
    isInCracow: string, 
    polishLanguageLevel: string,
    englishLanguageLevel: string,
    otherLanguage: string,
    otherLanguageLevel: string,
    githubLink: string,
    linkedinLink: string,
    industries: string,
    cvUrl: string;
}



export interface IFilters {
    university: Array<string>;
    yearOfBirth: Array<string>;
    yearOfStudy: Array<string>;
    gender: Array<string>;
    polishLanguageLevel: Array<string>;
    englishLanguageLevel: Array<string>;
    industries: Array<string>;

}

export const dataIsInCracow = [
    "Tak",
    "Nie",
]

export const dataYearsOfBirth = [
    "1980",
    "1981",
    "1982",
    "1983",
    "1984",
    "1985",
    "1986",
    "1987",
    "1988",
    "1989",
    "1990",
    "1991",
    "1992",
    "1993",
    "1994",
    "1995",
    "1996",
    "1997",
    "1998",
    "1999",
    "2000",
    "2001",
    "2002",
    "2003",
    "2004",
]

export const dataGender = [
    "Nie chcę podawać",
    "Inna",
    "Kobieta",
    "Mężczyzna",
]

export const dataUniversities = [

    "Akademia Górniczo-Hutnicza",
    "Akademia Ignatium",
    "Akademia Morska w Szczecinie",
    "Akademia Sztuk Pięknych w Krakowie",
    "Akademia Wychowania Fizycznego w Krakowie",
   "Krakowska Akademia im. Andrzeja Frycza Modrzewskiego",
    "Politechnika Białostocka",
    "Politechnika Częstochowska",
    "Politechnika Gdańska",
    "Politechnika Koszalińska",
    "Politechnika Krakowska",
    "Politechnika Lubelska",
    "Politechnika Łódzka",
    "Politechnika Opolska",
    "Politechnika Poznańska",
    "Politechnika Białostocka",
    "Politechnika Warszawska",
    "Politechnika Wrocławska",
    "Wojskowa Akademia Techniczna",
    "Uniwersytet Jagieloński",
    "Uniwersytet Morski w Gdyni",
    "Uniwersytet Pedagogiczny",
    "Uniwersytet Papieski Jana Pawła II",
    "Uniwersytet Rolniczy",
    "Zachodniopomorski Uniwersytet Techniczny",
    "Inna",
]

export const dataYearOfStudy = [
    "I",
    "II",
    'III',
    'IV',
    'V',
    'absolwent studiów I stopnia',
    'absolwent studiów II stopnia',
    'doktorat',
]

export const dataPolishLanguageLevel = [
    "Język ojczysty",
    "A1",
    "A2",
    "B1",
    "B2",
    "C1",
    "C2",
]

export const dataEnglishLanguageLevel = [
    "Język ojczysty",
    "A1",
    "A2",
    "B1",
    "B2",
    "C1",
    "C2"
]

export const dataIndustries = [
    "Analiza danych",
    "Architektura",
    "Automatyka",
    "Backend Developer",
    "Biotechonologia",
    "Budownictwo",
    "Cyberbezpieczeństwo",
    "Chemia Przemysłowa",
    "Chemia w Kryminalistyce",
    "Data Science",
    "E-commerce",
    "Edukacja",
    "Elektryka",
    "Ekologiczne źródła energii",
    "Ekonomia",
    "Energetyka",
    "Event Management",
    "Finanse i rachunkowość",
    "Frontend Developer",
    "Full-stack Developer",
    "Geodezja i kartografia",
    "Geoinformatyka",
    "Górinictwo",
    "Grafika",
    "Hardware Developer",
    "Helpdesk w IT",
    "Human Resources",
    "Hutnictwo",
    "Inyniera Biomedyczna",
    "Inżynieria materiałowa",
    "Inżyniera naftowa i gazownicza",
    "Komputerowe Wspomaganie Procesów Inynierskich",
    "Kryminalistyka",
    "Księgowość",
    "Logistyka",
    "Marketing i media",
    "Matematyka Stosowana",
    "Materiałoznactwo",
    "Mechatronika",
    "Metalurgia",
    "Medycyna",
    "Mikro- i Nano-technologie ",
    "Mikroelektronika",
    "Mobile Developer",
    "Motoryzacja",
    "Ochrona środowiska",
    "Ochrona własności intelektualnej",
    "Odnawialne źródła energii",
    "Optyka",
    "Product Management",
    "Produkcja przemysłowa",
    "Project Management",
    "Projektowanie stron internetowych",
    "Public Realtions",
    "QA Engineer",
    "Rekrutacja",
    "Rewiatalizacja terenów zdegradowanych",
    "Robotyka",
    "Software engineering",
    "Spedycja",
    "Sprzedaż",
    "Socjologia",
    "Technologia Chemiczna",
    "Telekomunikacja",
    "Tester Oprogramowania",
    "UX/UI",
    "Zarządzanie procesami przemysłowami",
]


export const serverUrl = "https://safe-refuge-89722.herokuapp.com";
// export const serverUrl = "http://localhost:3001";