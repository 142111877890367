import { IFilters } from "../constants";

const initalState: IFilters = {
    university: [],
    yearOfBirth: [],
    yearOfStudy: [],
    gender: [],
    polishLanguageLevel: [],
    englishLanguageLevel: [],
    industries: []
};

export default (state: IFilters = initalState, action: any) => {
    switch (action.type) {
        case "UPDATE_FILTER":
            return action.payload;
        default:
            return state;
    }
};
