import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from "./components/App";
import LoginPage from "./components/LoginPage";
import configureStore from "./store/createStore";
import React from "react";
import SignIn from "./components/SignIn";

const store = configureStore();

render(
    <Provider store={store}>
        <Router>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/login" element={<SignIn />} />
            </Routes>
        </Router>
    </Provider>,
    document.querySelector("#root")
);
