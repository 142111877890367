import { IAction, IUser } from "../constants";

const emptyUser: IUser = JSON.parse(
    window.sessionStorage.getItem("userData") as string
) || {
    userName: "",
    JWT: "",
};

const initialState =
    JSON.parse(window.localStorage.getItem("userData") as string) || emptyUser;

export default (state: IUser = initialState, action: IAction) => {
    switch (action.type) {
        case "LOGIN":
            window.sessionStorage.setItem(
                "userData",
                JSON.stringify(action.payload)
            );
            return action.payload;

        case "LOGOUT":
            window.sessionStorage.removeItem("userData");
            return emptyUser;

        default:
            return state;
    }
};
